import React from "react";

function Dailog({ blockData, onCardClick }) {
  const { names, images, price } = blockData[0];

  const uniqueArrayColors = [
    ...new Set(blockData.map((item) => item.color).flat()),
  ];
  return (
    <div>
      <div>
        <div
          className="bg-white text-white lg:w-[295px] sm:w-[260px] h-72 rounded-[30px]  mb-[24px]  flex flex-col justify-center items-center cursor-pointer 
              transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110  duration-300 "
          onClick={() => onCardClick(blockData)}
        >
          <img
            src={`https://media.bbf-bike.de/shop/images/${images[0]}.jpg`}
            alt="bike1"
            className="rounded-xl p-8"
          />
 <div className="bg-[#A07A10] w-full flex justify-center flex-col items-center p-5 rounded-bl-xl rounded-br-xl">
          <div className="flex">
            {uniqueArrayColors.map((item) => (
              <div>
                {" "}
                <div
                  style={{
                    width: "22px",
                    height: "22px",
                    borderRadius: "50%",
                    margin: "5px",
                    backgroundColor: item,
                    cursor: "pointer",
                    border:" 2px solid white",
               
                  }}
                ></div>
              </div>
            ))}
          </div>
         
          <h1 className="font-medium">{names[0]}</h1>
          <h1 className="font-medium text-2xl">€ {price[0]}</h1>
          </div>
        </div>

        <div></div>
      </div>
    </div>
  );
}

export default Dailog;
