import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Dailog from "./Dailog";
// import "./ImageSlider.css"
import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from "react-zoom-pan-pinch";
const Controls = () => {
  const { zoomIn, zoomOut, resetTransform } = useControls();

  return (
    <>
      <div className="flex justify-evenly items-center py-2">
        <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB8
     0lEQVR4nO2Y0UoCQRSGv4sije1FeoGE7M43WC9KU7G6LIsKJBTSrnqYoAcw74KeIaonqK6VFIyFMyCy2rY7O+4s/nAYHMdhPs/MOWcGVkqfskA
     OqALXQFPaqvR73ydWW8Ax0AeGwGSBDWWcN94hIcoCbeD7j8XPsy+gtWwv7QEfIQFmzZsnvwyIc2CkCUKZN1/DJMSdZoBZ65qAuIgZQlkj7jMxMg
     QyiuvMZDUe7KD2DmR0g7RDLuYZuJc2zO9bOiGcCHmiI3N0IuQZRxfISYTtERVkAhzpAukvGaSnA2IzQO0UN8hARwmTi7AAXSATYCcqSC0hINWo
     IFf/CLF+VpB5CgvGBAnNlybyh/rXwyqIt9omPNKTW6CfqTIjv2BMz4RHamk5I7m0RK2MxHHr84inpzRk9iTUWnU0yZEq1PrqF7kXhN0WQUOsn9
     2gWRvAa4TtkZgbogrFP7bf2ZUahkDOMKBuGt61pj0Tx0vjqcy/DjwAZQwoLwdSB8QbsDt1K32U/jFQMgGTkdAcNs98SohV0WkNeJkZMwYOMCRHX
     jt6AWqzgYyrz0l2RZ9tOzYJo5SVarUi95mmtBXpD1IAzoPZx0K5PnnLgzkkRTBlLJS7grHMMyUslJsmmKJPaPY+b5MCz9xisVyBsRpCycrttBI2
     6xe1uIydjJb19wAAAABJRU5ErkJggg=="
          className="h-7 w-7 cursor-pointer mr-3"
          onClick={() => zoomIn()}
        />
        <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB1klEQ
     VR4nO2Y3UoCQRiGn4MiN7Yb6QYSsqtYD0pTsTosiwokFNKOupigC9g8E7qGqK4gO1ZSMBa+ARGtxZ2d3Vl84WXZdZjxYXa+n4W1sicHyANV4BZoy
     rUqz4PfU6sd4BToASNg+odHMi4Y75ISOUAb+P7nzy/zAGglvUsHwOeKAPMO5ikkAXEJjDVBKAfzNUxCPGgGmHfXBMRVzBDKjbjPxNgQyDiuM+N
     oPNhh/QHkdIO0DUMot3RCuBHyRFQPdCbNs4QglE90gfQSBvF1QGyHqJ3i9lBHCZNPGGIq3osKUksBxFRagEi6CbFIH3iM4H6INa5N5I9OxDU6
     IdZom9gRX7rAVe2b2JFaVs5IPgUQUx1RKydx3Po8EuglC5k9DbVWHU1ypQq1vvpF+oIkQO7QrC3gLQsdogrFP7b37EoNQyAXGFA3C9+1Zncmj
     i+N5zL/JvAElDGgghxIHRDvwP5MV/oszydAyQRMTkLzqnnmS0Ksik4bwOvcmAlwhCG58rXDD1GbDWVcfUmyKy54bScmYZQcqVYr0s805VqR52
     EKwGUwh1gob0HeCmCOyRBMGQvlrWEs25kSFsrLEkxxQWgO7nfJwM7cY7E8gbEaQsnK12ktbNYvgAGoc5+g3OUAAAAASUVORK5CYII="
          onClick={() => zoomOut()}
          className="h-7 w-7 cursor-pointer mr-3"
        />
        <img
          src="https://img.icons8.com/sf-black/64/recurring-appointment.png"
          alt="recurring-appointment"
          onClick={() => resetTransform()}
          className="h-7 w-7 cursor-pointer"
        />
      </div>
    </>
  );
};
const Articles = () => {
  const [blocks, setBlocks] = useState([]);
  const [articles, setArticles] = useState([]);
  const [selectedBlock, setSelectedBlock] = useState(null);
  const [secondary, setSecondary] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");
  const [list, setList] = useState([]);
  const closeRef = useRef(null);
  const { article } = useParams();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [pause, setPause] = useState(false);

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const mouseInside = () => {
    setPause(true);
  };
  const mouseOutside = () => {
    setPause(false);
  };
  const listArray = async () => {
    try {
      const response = await axios.get(
        "https://data.bbf-bike.de/catalog/list/categories/25"
      );
      setList(response.data);
    } catch (error) {
      console.log("error generated", error);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    listArray();
  }, []);
  const id = list?.find(
    (item) => item.bbf_cat_SHORTNAME === article
  )?.bbf_cat_ID_PUBLICGRPORDER;
  const name = list?.find(
    (item) => item.bbf_cat_SHORTNAME === article
  )?.bbf_cat_name;
  const description = list?.find((item) => item.bbf_cat_SHORTNAME === article)
    ?.translations.german.DESCRIPTION;
  const findParent = list?.find(
    (item) => item.bbf_cat_SHORTNAME === article
  )?.bbf_cat_parent_category;
  const parent = list?.filter(
    (item) => item.bbf_cat_ID_PUBLICGRPORDER === findParent
  );

  const handleClickOutside = (e) => {
    if (!closeRef.current?.contains(e.target)) {
      setSelectedBlock(null);
    }
  };

  const blockAndArticles = async () => {
    try {
      const response = await axios.get(
        `https://data.bbf-bike.de/catalog/list/categories/articles/${id}`
      );
      setBlocks(response.data.blocks);
      setArticles(response.data.articles);
    } catch (error) {
      console.log("error is generated", error);
    }
  };
  useEffect(() => {
    blockAndArticles();
  }, [id]);
  const groupedBikes = {};

  blocks.forEach((block) => {
    const bike = articles.find((bike) => bike.ID_PARTS === block.ID_PARTS);
    if (bike) {
      if (!groupedBikes[block.BLOCK]) {
        groupedBikes[block.BLOCK] = [];
      }

      const existingProduct = groupedBikes[block.BLOCK].find(
        (p) =>
          p.images.join(",") ===
          bike.media.map((img) => img.IMAGENAME).join(",")
      );

      const findTheSizeAndModelName = (matchingString) => {
        for (const element of bike.properties.default.primary) {
          if (element.ATTRIBUTE === matchingString) {
            return element.VALUE;
          }
        }
        return null;
      };
      const findColor = (matchingString) => {
        for (const element of bike.properties.default.primary) {
          if (element.ATTRIBUTE === matchingString) {
            return element.SHORTNAME;
          }
        }
        return null;
      };
      const secondDescriptionPopUp = bike.descriptions.TEXT6;
      const rimSize = findTheSizeAndModelName("Rahmenhöhe");
      const name = findTheSizeAndModelName("Schaltung");
      const color = findColor("Farbe");
      const PrimaryProperties = () => bike.properties.default.primary;
      const englishTranslations = bike.properties.translations.english;
      const properties = PrimaryProperties();

      const filteredPrimaryProperties = properties.filter(
        (item) =>
          item.ATTRIBUTE !== "Artikelgruppe" && item.ATTRIBUTE !== "Rahmenhöhe"
      );

      const secondaryProperties = () => bike.properties.default.secondary;
      const propertiesSecondary = secondaryProperties();

      const filteredSecondaryProperties = propertiesSecondary.filter(
        (item) =>
          item.ATTRIBUTE !== "Bauform" &&
          item.ATTRIBUTE !== "Federgabel" &&
          item.ATTRIBUTE !== "Dämpfer" &&
          item.ATTRIBUTE !== "Webshopjahr" &&
          item.ATTRIBUTE !== "Geschlecht" &&
          item.ATTRIBUTE !== "Auswertungsmarke"
      );

      const propertyEnglishPrimary = filteredPrimaryProperties
        .map((item) => item.ID_PARTPROPERTYDEFINITION)
        .map((items) =>
          englishTranslations.filter(
            (item) => item.ID_PARTPROPERTYDEFINITION === items
          )
        )
        .flat();
      const propertiesSecondaryEnglish = filteredSecondaryProperties
        .map((item) => item.ID_PARTPROPERTYDEFINITION)
        .map((items) =>
          englishTranslations.filter(
            (item) => item.ID_PARTPROPERTYDEFINITION === items
          )
        )
        .flat();
      const primarySetup =
        currentLanguage === "de"
          ? filteredPrimaryProperties
          : propertyEnglishPrimary;
      const secondarySetup =
        currentLanguage === "de"
          ? filteredSecondaryProperties
          : propertiesSecondaryEnglish;
      if (existingProduct) {
        existingProduct.partId.push(bike.ID_PARTS);
        existingProduct.Rahmenhöhe.push(rimSize);
      } else {
        groupedBikes[block.BLOCK].push({
          names: [name],
          images: bike.media.map((img) => img.IMAGENAME),
          Rahmenhöhe: [rimSize],
          partId: [bike.ID_PARTS],
          primary: [primarySetup],
          secondary: [secondarySetup.concat(primarySetup)],
          price: [bike.RESELLERPRICE],
          color: [color],
          popupdesc: [secondDescriptionPopUp],
        });
      }
    } else {
      console.log("bike is undefined for block", block);
    }
  });

  const showSeconday = () => {
    setSecondary((prev) => !prev);
  };

  const handleCardClick = (blockData) => {
    setSelectedBlock(blockData);
  };
  const handleColorClick = (color) => {
    setSelectedColor(color);
  };
  const images = selectedBlock?.find((img) =>
    img.color.find((color) => color === selectedColor)
  );

  const prevImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images?.images?.length - 1 : prevIndex - 1
    );
  };
  const nextImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images?.images?.length - 1 ? 0 : prevIndex + 1
    );
  };
  useEffect(() => {
    if (!pause) {
      const timer = setTimeout(() => {
        setCurrentIndex((prevIndex) => {
          const nextIndex =
            images && images?.images.length > 0
              ? (prevIndex + 1) % images.images.length
              : 0;

          return nextIndex;
        });
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [currentIndex]);
  const mainImage = Object.values(groupedBikes)
    .map((blockData) => blockData)
    .flat()
    .map((img) => img.images)
    .flat();

  useEffect(() => {
    const dynamicColors = [
      ...new Set(selectedBlock?.map((item) => item.color).flat()),
    ].find((item) => item);

    setSelectedColor(dynamicColors);
  }, [selectedBlock]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleZoom = (event) => {
    const zoomFactor = event.deltaY > 0 ? 4 : 2;

    event?.target?.getTransform().scaleBy(zoomFactor);
  };
  return (
    <div className="min-h-[calc(100vh-68px)] h-auto bg-[#000000]">
      <h1 className="flex justify-center items-center text-4xl font-extrabold underline sm:mb-[0px] md:mb-4 xl:mb-4"></h1>

      <div className="flex justify-center items-center  sm:flex-col xl:flex-row sm:flex-wrap sm:mt-[0px] md:mt-16 xl:mt-10"></div>

      <div className="bg-[#000000] xl:pt-6 sm:mt-[0px] pb-10 flex flex-col ">
        <div className="flex  flex-row items-center md:justify-around sm:justify-center md:mt-20  sm:mt-10 md:mx-20">
          <div className="relative  md:block sm:hidden">
            <Link to={`/${parent[0]?.bbf_cat_SHORTNAME}`}>
              <div className="hover:text-[#A07A10] h-10 w-28 text-white rounded-xl md:flex md:justify-center md:items-center sm:ml-6 sm:mt-[6px] md:mt-0 md:ml-0">{`${parent[0]?.bbf_cat_SHORTNAME
                .toUpperCase()
                .charAt()}${parent[0]?.bbf_cat_SHORTNAME.slice(1)}`}</div>
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAFz0lEQVR4nO1bW29VR
      RSeClq8gC+CvklQiEIk/ggj12JiYlIlVgwSCWAJT4Y3L+EioqitiYlGog8a8RKjj4CJUVTUEnwzQJCLpdaenP19swuIko5ZdU5dne7TnrZ777PP0S+Zh57OnjVrzc
      yadRtjcoJzbiaAO0muIvk4gC0AnvZtC8l1JFdKH+lrGh2lUmkOyTUAXiF5HMAVkq6W5vvKN/tItslYphHgnJsB4D4A75K8WCvDNQjkDwCfA3jIOXetKRqcc9f7bXx
      2AmbOkTwI4G2SXSR3+9YFYD/JQ77PeGOcAbDJOTerCIxfE0XRBgB9VSb7I8ldAO7v7++/qdZxBwYGZgNY5oXTU2VXXLDWrnfOtZh6IIqiewF8mzCx30jutNbenRYt
      a+1iEQaA/gR6R8rl8lKTF5xzLSS3JSi1s3IM5DhkRbu3t/cGkp0kzycozc7Md0OpVJoD4NOA+GWSz+Z5JkXIJJ8X5RjM5RM5PpkQjeP4VpLHAoLfkVxo6gSSi0geD
      XZiTxzH81IlFEXRfJInFeNDAPYW4Upyzl0n9oLMSQnhpMw5FQLW2rkAflbMXxXtawoGko8C+FMJ4dTg4OBtaVhzx9SgYtysMgUFydUALulreMo6wTnXIkpFrbxId6
      UpOGSBAPyl5v3ZlG4Hktv0mZctZhoE3tka0QkAnpqKkXNFDbDXNBgAvKp9iTiO76nZvAXwjTpHR0XTmgaD3FCaDwBf13QUoijaEBg5dbvnpwtr7V2Bxbpu3A+cc7PE
      yVAfPGMaHCR3aAdqXIsVwGZt2xfC5UzBfwDwqxLCxsSOzrmZ2p8Xx8Y0CUhuVQv7S2LIjeRq7dJm6dWNY3esBfBw2l6d3wXalR5rz5A8oDrsNDnCu9hvqAV4JG0aAF
      5Q/L2f5OZernRIM5gxWea9ANrTphPH8RI1/qVRJjLJNdp+NnVkXv7OKrAhkWZF51+fxoeuc93+nvnXg5V/UwyxrGgC2KNovZQoGQlgmiZb+QoALFf0jumMzbC1JA7E
      ZKK3jcS80nVDFf/AOTdDVn+hmsi5Ztv2IQD0KtoLhv1nNaGDzbjyGiQPK/orjE9KViSyv5mZFwB4R82hw/iUVuWH15qZeQHJ7lHmPoDtWV6BYtUVhXmB8KgEsD0PAaw
      tugC2qMl1/ceOwOb/lSBHX4OHspJ8UXYCyS/Ca3Ch+uF8lsS9EEa2oN+Gb+VsCF0YZQi5wBTOLLtagJ1QLpdvHmMKJzhDy0zGqJcQZMuPcYYEvhqr8o9dJgfU4zgA
      eDEx2UOyLVEyzRcQ+SkxIFIaGxJbbOobEks9JiipMUXj4hi3n+QHqsNuU9+gaHuW0SCS743pQGUPSAhZQskm/7B4u7S0j0BfX9+NAH4fdf9XSYycUZ06TZNAp/pJnq
      5aiwxgkzaK8k6OZAG/+joK9GTVzs65Vt2Z5HOmwSHXerCoreN+YK1dr6QldXiLTINCEjxBeryjVmV0RH30fYMWSLT6WuXKYn5Vs3Itl8tLgxKZfabBEPj9UiKzZLIDd
      KoBhiasrigQrLVPBIbVpikNBOBjNcjVKIoeNAWHmPW6TI7kh9MtlOxRQpAixDZTUFhrHwgKJX+YdqbLWntLWCorhVSmYCD5WLDyp6TAO5XBoyiaD+CE1glSh1eE28Fr
      +9CtPlEul29PlVAcx/P0tVLZYlKKZup4z1eZ09xMCA4MDMwm+VEgbbkud+TpPIl5698iha9WDmSd3R6G1N4mvNaQUrStWQrCM74tMNcrz+qmdtVNJ8Ag1lWwAsOutB
      QkTdrwmJjWnsClrdD7Mk1aUwlkrNOFiEE77ie+fDKvP/31u8LH8HQYSzd5ONVRzzRb6EVulCLEKpPVx+SwT1F3q4eT3f63w+H2TminxaWd0KurB9w/leaVp7ODEzBS
      c5N4ZaGfziZBtLF/wvKyRJpDpTkBw9K3Ryq5JFSXi2Y3GcM/rL7DJyc6JDOrns9LkXaHP/cLRjI2OeBvNEgdeCDr0lMAAAAASUVORK5CYII="
                className="object-contain cursor-pointer sm:h-5 md:h-6 hover:opacity-80 absolute inset-0 mt-2"
              />
            </Link>
          </div>

          <h1 className="font-bickham-semiold sm:text-6xl md:text-8xl xl:text-8xl  mt-2 text-white py-5 sm:mb-[8px] md:mb-[0px] xl:mb-[24px] ">
            {name}
          </h1>
          <div className="md:w-40"></div>
        </div>
        <div className="w-full bg-white flex justify-center items-center xl:py-10">
          <img
            src={`https://media.bbf-bike.de/shop/images/${mainImage[0]}.jpg`}
            alt="parentElement"
            className="sm:w-[250px] sm:h-[250px] md:h-[450px] md:w-[350px] xl:w-[547px] xl:h-[314.1px] md:mt-0 sm:mt-0 object-contain rounded-3xl"
          />
        </div>
        <h1 className="xl:mx-72 md:mx-36 md:mb-[20px] sm:my-10 xl:my-[50px] text-white font-lato-thin sm:px-8 md:px-0 flex items-center justify-center">
          <p>{description}</p>
        </h1>
        <div className="lg:flex lg:flex-row lg:justify-center lg:items-center gap-5 sm:flex sm:flex-col sm:justify-center sm:items-center sm:mt-10">
          {Object.values(groupedBikes).map((blockData, index) => (
            <Dailog
              key={index}
              blockData={blockData}
              onCardClick={handleCardClick}
            />
          ))}
        </div>
      </div>

      {selectedBlock && (
        <div
          className="fixed inset-0 z-50 lg:flex md:flex-row md:items-center md:justify-center
          sm:flex sm:flex-col  overflow-x-hidden    outline-none focus:outline-none 
        transition-transform  duration-700 transform 
        hover:opacity-100 backdrop-blur-sm md:bg-white/20 sm:bg-white/100 shadow-xl animate-in fade-in text-white"
        >
          <div
            className="md:flex md:flex-row md:items-center md:justify-center
               sm:flex sm:flex-col sm:items-center  md:h-[90%]  lg:mt-[0px] bg-black md:rounded-2xl  overflow-y-auto"
            ref={closeRef}
          >
            {/* the first part of the div with back button, color pallette, slider, features */}
            <div
              className=" lg:pt-56 md:pt-56 sm:px-4  md:pb-4 xl:px-10 xl:pt-14 
           xl:w-[500px] md:w-[400px] sm:w-full text-white flex-1 md:h-full "
            >
              <div className="flex justify-end">
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpw
              YAAAF2ElEQVR4nO1bS48VRRQuNYKoEUeNr8Qngu93jI8F4GutIn8AfCACYtTFSDTCTtwYIwiiMIwrUcREVwoLQECDD4xERBaIiwkhm
              en6vurLTDAjZY5TTc4091567u2+t6/Ol3RyM1NV59F16pyq+tqYcYxjHEXCe3+Wc24GgFcB9ADYSfIPkhGAv+SR3/I3+R+AdQC6p
              Y/0NZ0Ia+35zrmnSG4BMETSN/KEvptJzpUxTdkRx/HNAHqbMbqeMwCsd87daMoGANcB2ATgeBXFjwPYS3IFgGettQ9aa68B0OW9P1
              Me+Q3gWmvtQwDmkVwFYF8NR/xNcqO0L0V8k1xW7Y2HeF4Qx/HFjY5/9OjRy0m+SPKHGjPiDe/9RNMOkJxGck/67QD4EsA9ecuz1t4
              J4CMAwyln/ERyat7y6sJaO5ukSxm/NY7jW0zBEOeS3J1ygrPWzipa9r+QaR3iMBEek5zjvT/NtAje+9MBLNShF2bf/EIFA1iSeuu
              /OOduMG1CFEW3Adif0mlJIcIAzE9P+f7+/vNMmyH1AYBtqZBYnLeQ2XraA/iiTFWa6CI66XCw1j6ZW47nCJLBt5XJ+ATe+wkkv9Zr
              U9Ph6Ufy/M/K+H1lLkklJEPRlThhT1Mvi+QyXXhEUXS7KTlCfaJT9NJmytsh5YBiU0yOCBsxXTFOGfMgADapQb6T3Gs6BFKTANiu
              9P9sTAM4525KNjahwLjbdBhCjTCc2CA2Ze4MoFd573PToSC5QdnRm6lTFEWTAQyqReR+06GQRVvN5KFMGcw597Qy/nvT4QCwK7FHF
              sdTdiC5Jc+SUmZUI+lTYjiPmgPAImXP5iwl5VByklOpVC5rRvjhw4fPIXkojLfee39GlhWc5HuhT9+RI0fObUaHSqVyqQ6DuoWRtX
              amrvpMk5D8m9pA9dZLp8H41ak+U3LQ41cVBjPqNexWwlc1KziM+VbKoJ5qTqhmvJwj5qGD2KLkd9dTtkc1nJeH8KDAmyknrNNOCMa
              vTLX5IK/iC8BzWna9hjuThnJ6m4dwNfbylIFrxcCijRdYax9WY++o2ZDkn8oBV5scEQx9NzXF368y7VfnfbwmR+hq/EM1G5IcUJ7
              qMjmjhhMKe/MJSF6oZPTXbAjgWNJQLixMAQhOWNEq49VhSSLnWFYHTChImZNiPij2YdsdwNEhcEGrjC/aCZlDgMUugnKWvzZl9Kp
              SLYIYnQZn5mz8iRojPGtUGlxR5EwA8Kga+5t6DXuUA57JQ7jU/7IPqPeWi3YCgOezFkLdSomVOQk/cbgSFHin2hSvUQrnooPMt
              kylsHNuhmq4t1nB1to7Usa/XS++C9wMHVCboemZt8NxHF/S7FlA4APJeMuz9NHhAOC3Zi9hoii6Ujlz8JR8Ao5wcpIOC0yT6Ov
              rO7uRjCIrdx43UCRfUTPqq7Geqe8yHY5AoEgcMCfr9dKgckLHHYknIHlf6mZrcqaOGJ22PjYdCqHrZEp/aQgVLbkOl8uFOI5vNR0Gk
              vfqyx2S1491gI3Ke9tbSYFpFlJApZhlG0wjqzBG09/mmg4ByRd06mt4X0NyqXJAZUz3a22ChGtqEX+t4cG89xN1GpHqsAy8oFqQ
              Lbyu+iQMmiZS4mSKzNaSUmQmyU5PGS8kiWm5DG6tnVVyktSkFElq2Dn3WK5CMEJi1puUb51zF+UqpDG9ujQZIui2qFVEyf3t5A0
              F/vCBlhAl9S2LJiwHDs7CVlJoQp5fnOIwDbeMx+Sce1wvjOHZXQRLvFqFV4U+T+fcE6aViKLoKlkHqnwcIfX3A3nLs9beRfKTKh9
              ltJ4un0ByLIDXU5Sa5PmR5EsDAwNXmAYhfUm+nNrSnqjw2vrBhIaUmuHtaBq9VvZ3uQeUGA0XlVPDJzMT5JHf4W+PBFL2mvTips
              YSGZ+W4pOZNISbGz57+399NFXjLHCOkJdrhEdWowcDAXpu5sOMssF7P1FOYuU4OsyOHSQPVvlw8qD8T304Ob0U8T2OcZj/NP4B+7
              p9n4jMWDMAAAAASUVORK5CYII="
                  className=" sm:block md:hidden w-8 h-8 my-5"
                  onClick={() => setSelectedBlock(null)}
                />
              </div>
              <div className="flex items-center justify-center mb-10 ">
                {[
                  ...new Set(selectedBlock.map((item) => item.color).flat()),
                ].map((item) => (
                  <div>
                    <div
                      style={{
                        width: "22px",
                        height: "22px",
                        borderRadius: "50%",
                        margin: "5px",
                        backgroundColor: item,
                        cursor: "pointer",
                        border: "2px solid white",
                      }}
                      onClick={() => handleColorClick(item)}
                    ></div>
                  </div>
                ))}
              </div>

              {/* end of the color pallette */}
              {/* <div className="bg-white rounded-xl">
              <TransformWrapper>
                    <Controls />
                    <TransformComponent>
                <Carousel
                  showThumbs={false}
                  showArrows={false}
                  showStatus={false}
                  autoPlay={true}
                  interval={3000}
                  stopOnHover={true}
                  thumbWidth={50}
                  className="flex flex-col justify-center items-center"
                  renderIndicator={(
                    onClickHandler,
                    isSelected,
                    index,
                    label
                  ) => {
                    const defStyle = {
                      marginLeft: 20,
                      backgroundColor: "black",
                      cursor: "pointer",
                      padding: "3px",
                      color: "white",
                      borderRadius: ".50%",
                      fontSize: "9px",
                    };
                    const style = isSelected
                      ? { ...defStyle, backgroundColor: "#A07A10" }
                      : { ...defStyle };
                    return (
                      <span
                        style={style}
                        onClick={onClickHandler}
                        onKeyDown={onClickHandler}
                        key={index}
                        role="button"
                        tabIndex={0}
                        aria-label={`${label} ${index + 1}`}
                      >
                        {index + 1}
                      </span>
                    );
                  }}
                >
                  {images?.images.map((img) => (
                    
                  
                    <img
                      src={`https://media.bbf-bike.de/shop/images/${img}`}
                      alt="bike"
                      className="md:w-56 md:h-56 xl:w-80 xl:h-80 object-contain p-4"
                    />
      
                  ))}
                </Carousel>
                </TransformComponent>
    </TransformWrapper>
              </div> */}

              <div className="bg-white rounded-md">
                <div
                  className="relative flex justify-center items-center flex-col"
                  onMouseOver={() => mouseInside()}
                  onMouseLeave={() => mouseOutside()}
                >
                  <TransformWrapper onWheel={handleZoom}>
                    <Controls />
                    <TransformComponent>
                      {images?.images.map((img, index) => (
                        <div className="flex justify-center items-center cursor-pointer">
                          <img
                            src={`https://media.bbf-bike.de/shop/images/${img}.jpg`}
                            alt={`Slide ${index + 1}`}
                            className={`md:w-56 md:h-56 sm:h-48 sm xl:w-80 xl:h-80 object-contain p-4 ${
                              index === currentIndex &&
                              typeof currentIndex === "number"
                                ? "block"
                                : "hidden"
                            }`}
                          />
                        </div>
                      ))}
                    </TransformComponent>
                  </TransformWrapper>

                  <button
                    type="button"
                    className="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                    onClick={prevImage}
                  >
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXM
          AAAsTAAALEwEAmpwYAAACSElEQVR4nO2az04UQRDGfycXDxpMFLzJ0QOc8CbeDMREI0f+PAOB8BaABw0mhiuPIKzKGx
          DjWdg/J8E1EPAsQijTSZlslpmdmZ7umR7Cl3zJbHantr5UT3VV9cANri/uAdPAGlAH9oHfwJnSXO/pd+Y3r4FBAkEN
          mAd2gAtAMtLc8xmYU1uF4zawDPy0cD6Oh8ASMFCUiBdA26GAXraAKZ8CTOjfeRTQy02NvFMMA98KFCHKr8CQKxEjGm4pi
          U31IRceaBqVktkGHtqKGChpOUmfZWaVoj8E4Lz0cN0mxUqgnEwrwoSvEYDDEsNW2k1zOQBnJYGLaaJxGICjksCDpAd/Puc
          fHAHjwEQBYmb7CdnJKWJU7TwpQMinfv3EhQMRo/rZt5Bz4G6UkGlLg8fAmNp4DPwqQIQoX0YJeVORSEgXV6OE1CsUCVF+jBL
          SrFAkRGkK2is4rVAkpMuXKzhLebNJrb7xNKUvf0IX8iyPENul1QltaTUq+LDv+Ui/nVDS75qFobIjs+KzROmUXaIMVqxo/BtX
          NBp8qVAZv00fzOU0fqQi0u4DeThzXVrdWyRgKQBHJYELSSL+RyWEManEsJll4jgVgMMSwUvgORnxPgDHpYdvsUBNB8cSCHfTPO
          BxuB/QscIwOTGSsQ12zQbwCEcYKmmZ7ephk5fD0MuCRGz4Pnuf9LzU9m1SrC1qOto/cCjgh+7YpbwBUdOpeF1nsVmdP9cqdiZPan
          UN0xu80jHmlvbTJ10v1Zjr79qermhTdMe5FzcgDPwDzOEXEz5EkHIAAAAASUVORK5CYII="
                      className="md:h-9 md:w-9 sm:h-6 sm:w-6 "
                    />
                  </button>
                  <button
                    type="button"
                    className="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                    onClick={nextImage}
                    data-carousel-next
                  >
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmp
         wYAAACRUlEQVR4nO2az0sVURTHP6ueLQqF0na5bKGrXJXLUIIil/74GyTxvzBbFAXRtj+hepX/gUTrfD5dpSVKutYMTwwcIR7z8947d
         84Tv/CFgfdm5nw4d+6cc+/ApS6uhoAZYBVoAx3gCDhRJ8cb+lvynyfAIEbUAhaANeAvIBWdnPMZmNdrRddVYBn46RB8lneBJWAgFsRD
         YDsgQK+3gOk6AZLUv6wRoNfvNPNBNQJ8iwgh6q/AcCiIUU23NOSuxuClmzqNSsPeBm65Qgw0NJwkZ5g5TdFvDAQvPX7lMsWKUU+VhUjS
         t2kgYMnwVtmX5rKBYKXAT8tkY9dAoFLgnaIHfyFCEPeBCWDf8zpzeSBrEUDu6r3GPGE+5fUTLqV4Ve8rhC/MKXA9DWQmAsS5D4Bxve8d
         4JfjdR6lgTyPCBIqM8/SQNqRQUJk5n0aSLcBEN/MdNJADhsC8cnMQRrIScmT71G/JkrGcuwDMmkd5NDQ0NrzGVqbffiwb1idfvdCTL+
         rfZQJUa9YK1GqZkLySpTBPisa/2QVjYm+9FEZ/5EczUcAmQzUWM1elFb3CgVaMhCoFHixCOI8KxaWSSXD3SorjtMGApYUnwEPqKjXBgK
         XHr/AQS1dOBYjXi/zgGfphqFthRE8NdpgGyxald8mkIYbGmbrutlUy2boWSSIt3XvvU/VPNQ6LlOsq1q6tL8TEOCHvrEb+QKipavibV2
         LrRr8qVaxsz5Ta2glvcFjXcb8oP307/8+qkmOv2t7uqJN0bXgUVwKG/oHEqcXDufAk+UAAAAASUVORK5CYII="
                      className="md:h-9 md:w-9 sm:h-6 sm:w-6"
                    />
                  </button>
                </div>
              </div>
              <div className="flex justify-center items-center  ">
                <div className="sm:font-bold text-sm sm:p-3 md:p-4">
                  {t("Furnishing")}
                </div>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    value=""
                    className="sr-only peer"
                    onClick={showSeconday}
                  />
                  <div className="w-11 h-6 bg-black border-2 border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-gray-300 dark:peer-focus:ring-gray-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-700 peer-checked:bg-[#A07A10]"></div>
                </label>
              </div>

              <div className="">
                {!secondary ? (
                  <div className="max-w-md mx-auto">
                    <p className="text-white leading-relaxed">
                      {images?.primary
                        .map((prop) => prop)
                        .flat()
                        .map((item, index) => (
                          <div
                            className={`flex flex-row justify-between gap-2  mx-2 p-1 ${
                              index % 2 === 0 ? "bg-gray-400" : "bg-black"
                            }`}
                          >
                            <h2>{item.ATTRIBUTE}</h2>
                            <h4>{item.VALUE}</h4>
                          </div>
                        ))}
                    </p>
                  </div>
                ) : (
                  <div className="max-w-md mx-auto ">
                    <p className="text-white leading-relaxed">
                      {images?.secondary
                        .map((prop) => prop)
                        .flat()
                        .map((item, index) => (
                          <div
                            className={`flex flex-row justify-between   mx-2 p-1 ${
                              index % 2 === 0 ? "bg-gray-400" : "bg-black"
                            }`}
                          >
                            <h2>{item.ATTRIBUTE}</h2>
                            <h4>{item.VALUE}</h4>
                          </div>
                        ))}
                    </p>
                  </div>
                )}
              </div>
            </div>
            {/* end of the second dev */}

            {/* the second part of the div with name, frame height, price and content from the marketing team */}
            <div className="bg-[#A07A10] p-10 sm:mt-10 md:mt-[500px] lg:mt-0 md:pb-[500px]  md:w-[400px] lg:w-[500px] text-white flex-1 ">
              <h1 className="text-7xl font-semibold mb-10 md:block sm:hidden font-bickham-semiold pt-[600px]">
                {" "}
                {name}
              </h1>
              <h1 className="text-3xl font-bold mb-10">
                {selectedBlock[0].names[0]}
              </h1>

              {/* features */}
              <div className="flex justify-between mb-10">
                <div className="flex flex-col">
                  <h1 className="text-2xl">Rahmenhöhe</h1>

                  <div className="lg:flex lg:flex-row sm:flex sm:flex-col sm:gap-1 lg:gap-3">
                    {[
                      ...new Set(
                        selectedBlock.map((item) => item.Rahmenhöhe).flat()
                      ),
                    ].map((item) => (
                      <div className=" sm:border-[0px] lg:border-2 rounded-xl mt-2  sm:p-[0px] lg:p-2 border-white">
                        {item}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="font-regular md:text-4xl sm:text-xl sm:ml-8 md:ml-[0px]">
                  € {selectedBlock[0].price[0]}
                </div>
              </div>

              <div className="pb-96">
                <p>
                  {selectedBlock[0]?.popupdesc[0] ? (
                    <div>{selectedBlock[0]?.popupdesc[0]}</div>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            </div>

            {/* end of the second div */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Articles;
